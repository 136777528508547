import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import AuthorizationService from '@/services/AuthorizationService';
import store from '@/store';

const auth = new AuthorizationService();

Vue.use(VueRouter);

const childRoutes: Array<RouteConfig> = [
  {
    name: 'start',
    path: '',
    component: Home,
  },
  {
    path: '/activiteit-overzicht',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: { breadcrumb: 'Activiteit overzicht' },
    children: [
      {
        name: 'activiteit-overzicht',
        path: '',
        component: () =>import('@/views/activiteit/ActiviteitOverzichtAndAdd.vue'),
      },

      {
        name: 'activiteit-te-valideren-overzicht',
        path: 'te-valideren',
        component: () => import('@/views/activiteit/ActiviteitOverzichtAndAdd.vue'),
        meta: { breadcrumb: 'Te valideren meldingen' },
        beforeEnter: (to, from, next) => {
          if (from.name === 'activiteit-overzicht') {
            next();
          } else {
            next('activiteit-overzicht');
          }
        },
      },
      {
        name: 'meldActiviteit',
        path: '/meld-activiteit',
        component: () => import('@/views/activiteit/MeldActiviteit.vue'),
        meta: { breadcrumb: 'Activiteit melden' },
      },
    ],
  },
  {
    name: 'activiteitLandingPage',
    path: '/:previousPage/activiteit',
    component: () => import('@/views/activiteit/ActiviteitLandingPage.vue'),
    meta: {
      breadcrumb(route: Route) {
        const previousPage = route.params.previousPage;
        const previousPageText = route.params.previousPageText;

        return [
          {
            text: previousPageText,
            to: {
              name: previousPage,
            },
          },
          {
            text: 'Activiteit',
          },
        ];
      },
    },
  },
  {
    path: '/afmelden',
    name: 'afmelden',
    component: () => import('@/views/Afmelden.vue'),
  },
  {
    path: '/beheerscherm',
    name: 'beheerscherm',
    component: () => import('@/views/beheer/BeheerLandingPage.vue'),
    meta: { breadcrumb: 'Beheerscherm' },
  },
  //keep * always as last.
  {
    path: '*',
    name: 'notFound',
    component: () => import('@/views/NotFound.vue'),
    meta: { breadcrumb: 'Onbekende pagina' },
  },
];

if (process.env.VUE_APP_LEEROMGEVING !== 'true') {
  //Needs to be inserted before '*' route. Therefor concat can't be used.
  childRoutes.splice(childRoutes.length - 1, 0, {
    name: 'meldStookoliePlaatsing',
    path: '/stookolie-plaatsing',
    component: () => import('@/views/stookolie-plaatsing/MeldStookoliePlaatsing.vue'),
    meta: { breadcrumb: 'Installatie melden' },
  });
}

//routes bouwen met de childRoutes van hierboven
const routes: Array<RouteConfig> = [
  {
    //HOME
    path: '//',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: { breadcrumb: 'Start' },
    //HOME/Children
    children: childRoutes,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _from, next) => {
  if (to.path !== '/' && process.env.NODE_ENV !== 'development') {
    auth.isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        next();
        store.dispatch('Errors/updateErrors', []);
      } else {
        //Access denied --> redirect naar home: inloggen!
        next('/');
      }
    });
  } else {
    next();
    store.dispatch('Errors/resetErrors');
  }
});

export default router;
