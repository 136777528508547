import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import VueI18n from 'vue-i18n';
import LocaleMessage = VueI18n.LocaleMessage;

export interface ErrorInterface {
  message: string | LocaleMessage;
}

@Module({ namespaced: true })
class Errors extends VuexModule {
  public errors: ErrorInterface[] = [];

  @Mutation
  public setErrors(errors: ErrorInterface[]): void {
    this.errors = errors;
  }

  @Action
  public updateErrors(errors: ErrorInterface[]) {
    if (errors) {
      this.context.commit('setErrors', errors);
    }
    this.context.dispatch('Loader/updateLoaderDone', {}, { root: true });
  }

  @Action
  public resetErrors() {
    this.context.commit('setErrors', []);
  }

  get getErrors() {
    return this.errors;
  }
}

export default Errors;
