import HttpService from '@/services/HttpService';
import { EnumInterface } from '@/domain/interface/intern/enumvalues/EnumInterface';
import { InputOptionInterface } from '@/domain/interface/intern/form/InputOptionInterface';

export class EnumService {
  constructor(public http: HttpService) {}

  private getEnum(name: string): Promise<EnumInterface[]> {
    return this.http.get('/omschrijvingen/' + name);
  }

  public excludeItems(array: EnumInterface[], except: string[] | undefined) {
    return array.filter((item) => !except?.includes(item.code));
  }

  public fetchEnum(name: string, except: string[] | undefined = undefined): Promise<EnumInterface[]> {
    return this.getEnum(name).then((data: EnumInterface[]) => this.excludeItems(data, except));
  }

  public fetchIndependentEnum(name: string, except: string[] | undefined = undefined): Promise<EnumInterface[]> {
    return this.fetchEnum(name, except);
  }

  public fetchDependentEnum(name: string, parentName: string, parentCode: string, except: string[] | undefined = undefined) {
    return this.fetchEnum(parentName).then((data: EnumInterface[]) => {
      return this.excludeItems(
        data.find((item) => item.code === parentCode)?.afhankelijkheden?.find((afhankelijkheid) => afhankelijkheid.veld.toLowerCase() === name.toLowerCase())
          ?.waarden || [],
        except
      );
    });
  }

  public mapForInput(array: EnumInterface[]): InputOptionInterface[] {
    return array.map((value) => {
      return {
        text: value.omschrijving,
        value: { omschrijving: value.omschrijving, code: value.code },
      };
    });
  }

  public mapCodes(array: EnumInterface[]) {
    return array.map((value) => value.code);
  }

  public getEnumFromOmschrijving(array: EnumInterface[], omschrijving: string) {
    return array.find((enumValue) => enumValue.omschrijving === omschrijving);
  }

  public getEnumFromCode(array: EnumInterface[], code: string) {
    return array.find((enumValue) => enumValue.code === code);
  }

  public getEnumFromCodeWithoutAfhankelijkheden(array: EnumInterface[], code: string) {
    const enumFromCode = this.getEnumFromCode(array, code);
    if (enumFromCode) {
      delete enumFromCode.afhankelijkheden;
    }
    return enumFromCode;
  }

  public getEnumFromChildCode(array: EnumInterface[], childEnumName: string, childCode: string) {
    return array.find((categorie) => categorie.afhankelijkheden?.find((a) => a.veld === childEnumName)?.waarden.find((w) => w.code === childCode));
  }

  public getDependentEnumFromEnumArray(array: EnumInterface[], afhankelijkheidVeld: string, parentCode: string) {
    return (
      array
        .find((item) => item.code === parentCode)
        ?.afhankelijkheden?.find((afhankelijkheid) => afhankelijkheid.veld.toLowerCase() === afhankelijkheidVeld.toLowerCase())?.waarden || []
    );
  }
}
